/* General Styling */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: 100%;
}

body.light-mode {
  background-color: #ffffff;
  color: #000000;
}

body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar */
.navbar {
  width: 100%;
  height: 60px;
  background-color: #6441a5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  flex-shrink: 0;
}

body.dark-mode .navbar {
  background-color: #333333;
}

.navbar a {
  color: white;
  margin-right: 20px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

.navbar a:hover {
  color: #f1f1f1;
}

/* Dark/Light Mode Toggle */
.theme-toggle {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.theme-toggle input[type='checkbox'] {
  width: 50px;
  height: 24px;
  appearance: none;
  background-color: #ccc;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.theme-toggle input[type='checkbox']:checked {
  background-color: #4b367c;
}

.theme-toggle input[type='checkbox']::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.theme-toggle input[type='checkbox']:checked::before {
  left: 28px;
}

.theme-toggle label {
  margin-left: 10px;
  font-size: 16px;
  color: white;
}

/* Layout: video feeds and chat sidebar */
.multistream {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.video-feeds-container {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

/* Video Grid Styles */
.video-grid {
  display: grid;
  justify-content: center;
  margin: 0 auto;
}

.stream {
  position: relative;
}

.stream iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}

.placeholder {
  /* Empty divs to fill the grid and center last row */
}

/* Remove Streamer Button */
.remove-streamer-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(100, 65, 165, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
}

.remove-streamer-btn:hover {
  background-color: rgba(75, 54, 124, 0.9);
}

/* Chat sidebar */
.chat-sidebar {
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: #f8f8f8;
  border-left: 2px solid #ccc;
  box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  flex-shrink: 0;
}

body.dark-mode .chat-sidebar {
  background-color: #222222;
  border-left: 2px solid #555555;
}

.chat-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

body.dark-mode .chat-tabs {
  border-bottom: 2px solid #555555;
}

.chat-tabs button {
  padding: 10px;
  background-color: #6441a5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.chat-tabs button:hover {
  background-color: #4b367c;
}

.chat-tabs .active {
  background-color: #473472;
  border: 3px solid #9a74ee;
}

.chat-iframe iframe {
  width: 100%;
  height: calc(100vh - 80px - 100px); /* Subtract navbar and other elements' heights */
  border-radius: 8px;
  overflow: hidden;
  border: none;
}

/* Home page styling */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.home h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.home input {
  padding: 10px;
  margin-right: 10px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

body.dark-mode .home input {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #555555;
}

.home button {
  background-color: #6441a5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
}

.home button:hover {
  background-color: #4b367c;
}

/* Add Streamer Button in Navbar */
.add-streamer-btn {
  background-color: #6f42c1;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 40px;
}

.add-streamer-btn:hover {
  background-color: #4b367c;
}

/* Add Streamer Popover */
.add-streamer-popover {
  position: absolute;
  right: 20px;
  top: 70px;
  width: 250px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 1000;
}

body.dark-mode .add-streamer-popover {
  background-color: #333333;
  border: 1px solid #555555;
}

.add-streamer-input {
  width: 92%;
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

body.dark-mode .add-streamer-input {
  background-color: #444444;
  color: #ffffff;
  border: 1px solid #555555;
}

.add-streamer-form button {
  background-color: #6441a5;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.add-streamer-form button:hover {
  background-color: #4b367c;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: #444;
}

.close-btn:hover {
  color: #ff4d4d;
}

/* Streamer List with Delete Links */
.streamer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.streamer-list span {
  flex: 1;
}

.delete-streamer-btn {
  background-color: transparent;
  color: #6441a5;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 14px;
}

.delete-streamer-btn:hover {
  color: #4b367c;
}
/* Container to hold the input and button inline */
.input-button-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px; /* Add padding inside the container to avoid cut-off */
}

.home input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  min-width: 90px; /* Ensures input is wide enough for the placeholder text */
  margin: 0 10px; /* Add margin to the input so it's not flush with the button */
}

@media (min-width: 768px) {
  .home input {
    min-width: 200px; /* Wider input for larger screens */
  }
}

.add-btn {
  border: 2px solid #6441a5;
  color: #6441a5;
  background-color: transparent;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-btn:hover {
  background-color: #6441a5;
  color: white;
}

.plus-icon {
  margin-left: 10px;
  font-weight: bold;
}

/* Streamer pill styles */
.streamer-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.streamer-pill {
  background-color: #6441a5;
  color: white;
  padding: 3px 8px;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.streamer-pill button {
  background-color: transparent;
  color: white;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.streamer-pill button:hover {
  color: #ff4d4d;
}

/* Watch button styles */
.watch-btn {
  background-color: #6441a5;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
}

.watch-btn:hover {
  background-color: #4b367c;
}

/* Instructions box */
.instructions-box {
  margin-top: 50px;
  padding: 15px;
  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.instructions-box p {
  margin: 0;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.instructions-box h2, h3 {
  margin-bottom: 20px;
  margin-top: 0px;
  color: #333;
}

.instructions-box h4 {
  margin: 20px;
  color: #333;
}

.faq-section {
  margin-top: 30px;
  padding: 15px;
  border: 2px solid #4b367c;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  text-align: left;
}

.faq-section p {
  margin: 0;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.faq-section h2 {
  margin-bottom: 30px;
  margin-top: 0px;
  color: #333;
  text-align: center;
}

.faq-section h3 {
  margin-bottom: 15px;
  margin-top: 15px;
  color: #333;
}

.faq-section h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
}


.logo {
  max-width: 400px; /* Adjust the size as needed */
  height: auto;
  margin-bottom: 40px; /* Adds some space between the logo and the heading */
}
